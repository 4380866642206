import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import JsonField from '@/shared/fields/json-field';
import ImagesField from '@/shared/fields/images-field';

function label(name) {
  return i18n(`entities.offer.fields.${name}`);
}

// function enumeratorLabel(name, value) {
//   return i18n(`entities.offer.enumerators.${name}.${value}`);
// }

const fields = {
  image: new ImagesField(
    'image',
    label('image'),
    `offer/images`,
  ),

  createdBy: new StringField(
    'createdBy',
    label('createdBy'),
    {},
  ),
  updatedBy: new StringField(
    'updatedBy',
    label('updatedBy'),
    {},
  ),
  //   createdAtRange: new DateTimeRangeField( 'createdAtRange', label('createdAtRange')),

  id: new IdField('id', label('id')),
  title: new JsonField('title', label('title')),
  description: new JsonField(
    'description',
    label('description'),
  ),
  images: new JsonField('images', label('images')),
  thumbnail: new StringField(
    'thumbnail',
    label('thumbnail'),
    {},
  ),
  termsAndConditions: new JsonField(
    'termsAndConditions',
    label('termsAndConditions'),
  ),
  TCTerms: new StringField(
    'TCTerms',
    label('TCTerms'),
  ),
  validityDate: new DateTimeField(
    'validityDate',
    label('validityDate'),
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
};

export class AuctionsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
