
import RelatedAuctionsList from '@/modules/auction/components/related-auction-list.vue';

// import { Hooper, Slide,
// Pagination as HooperPagination,
// Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import { AuctionsModel } from '@/modules/auction/auction-model';
import moment from 'moment';

export default {
  data() {
    return {
      viewsCount: 0,
      url:
        'https://www.smouhaclub.com' + this.$route.fullPath,
      slide: 0,
      likedArticle: false,
      isBookmarked: false,
    };
  },
  methods: {
    datePresenter(date) {
      console.log('this is the date', date);
      let newDate;
      this.isRTL
        ? (newDate = new Date(date).toLocaleString(
            `ar-EG`,
            { timeZone: 'Africa/Cairo' },
          ))
        : (newDate = new Date(date).toLocaleString(
            `en-EG`,
            { timeZone: 'Africa/Cairo' },
          ));
      console.log(newDate);
      return newDate;
    },
    ...mapActions({
      doFind: 'auction/view/doFind',
      doUpdateViews: 'auction/view/doUpdateViews',
      doFetchLikes: 'auction/view/doFetchLikes',
      doFetchUserLike: 'auction/view/doFetchUserLike',
      doFetchViewers: 'auction/view/doFetchViewers',
      doLike: 'auction/view/doLike',
    }),
    rowPresenter(row, fieldName) {
      if (this.isRTL) {
        return row[fieldName]['ar'];
      }
      return row[fieldName]['en'];
    },
    async doLikeArticle() {
      await this.doLike({
        articleId: this.record.id,
        userId: this.anonymousToken,
      });
      this.likedArticle = !this.likedArticle;
    },
    doBookmarkArticle() {
      this.isBookmarked = !this.isBookmarked;
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = AuctionsModel.presenter(row, 'title');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = AuctionsModel.presenter(row, 'title');
        return val ? val['ar'] : 'ـــــــ';
      }
    },
    presenterTermsAndConditions(row) {
      var val = AuctionsModel.presenter(
        row,
        'termsAndConditions',
      );

      if (!this.isRTL) {
        val['en'] = val['en'].replace('<p>', '');
        val['en'] = val['en'].replace('</p>', '');
        return val ? val['en'] : 'ـــــــ';
      } else {
        val['ar'] = val['ar'].replace('<p>', '');
        val['ar'] = val['ar'].replace('</p>', '');
        return val ? val['ar'] : 'ـــــــ';
      }
    },
    presenterDescription(row) {
      var val = AuctionsModel.presenter(row, 'description');
      console.log(val);

      if (!this.isRTL) {
        val['en'] = val['en'].replace('<p>', '');
        val['en'] = val['en'].replace('</p>', '');
        return val ? val['en'].split('\n') : 'ـــــــ';
      } else {
        //   this.paragraphs = this.article.body.split("\n")
        val['ar'] = val['ar'].replace('<p>', '');
        val['ar'] = val['ar'].replace('</p>', '');
        return val ? val['ar'].split('\n') : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      //   let now = moment().format('YYYY/MM/DD');
      let date = AuctionsModel.presenter(row, fieldName);
      //   let date =  `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date.seconds)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    presenterDate(date) {
      // date = moment.unix(date.seconds).format('DD/MM/YYYY');
      date = 0;
      return date;
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },

    async goToRelatedNewsArticle(recordId) {
      console.log('goToRelatedNewsArticle', recordId);
      await this.doFind({ id: recordId });
      this.$router.push({
        params: {
          id: recordId,
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      currentLanguageCode: 'layout/currentLanguageCode',
      // news:'news/list/news'
      record: 'auction/view/record',
      loading: 'auction/view/loading',
      anonymousToken: 'layout/anonymousToken',
      viewsCounter: 'auction/view/viewsCounter',
    }),
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar';
    },
  },
  components: {
    // Hooper,
    // Slide,
    // HooperPagination,
    // HooperNavigation,

    [RelatedAuctionsList.name]: RelatedAuctionsList,
  },
  async created() {
    let newDate;
    this.isRTL
      ? (newDate =
          new Date(`Tue Feb 28 2023 00:00:00 GMT+0200 (Eastern European Standard Time)
`).toLocaleString(`ar-EG`, {
            timeZone: `UTC`,
          }))
      : (newDate =
          new Date(`Tue Feb 28 2023 00:00:00 GMT+0200 (Eastern European Standard Time)
`).toLocaleString(`en-EG`, {
            timeZone: `UTC`,
          }));
    console.log('sadasdasdas', newDate);

    await this.doFind(this.$route.params.id);

    this.viewsCount = this.record.views.length;
    const myIp = await fetch(
      'https://api.ipify.org?format=json',
    ).then((x) => x.json());

    await this.doUpdateViews({
      id: this.$route.params.id,
      ip: myIp.ip,
    });

    this.viewsCount = this.viewsCounter;
  },
};
